import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "project-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_header_item = _resolveComponent("table-header-item")!
  const _component_tirscript3_table_body_item = _resolveComponent("tirscript3-table-body-item")!
  const _component_tirscript3_table = _resolveComponent("tirscript3-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tirscript3_table, {
      items: _ctx.projects,
      style: {"overflow-x":"auto"},
      "template-columns": "100px 200px auto"
    }, {
      header: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerItemList, (item, idx) => {
          return (_openBlock(), _createBlock(_component_table_header_item, {
            key: "idx",
            defaultSort: item.defaultSort,
            isNotActiveSort: item.isNotActiveSort,
            sortName: item.sortName
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["defaultSort", "isNotActiveSort", "sortName"]))
        }), 128))
      ]),
      body: _withCtx((projects) => [
        _createVNode(_component_tirscript3_table_body_item, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(projects.item.Id), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_tirscript3_table_body_item, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(projects.item.ServerName), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_tirscript3_table_body_item, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(projects.item.ProjectName), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}