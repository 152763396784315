
import GetAllProjectLogGroupsResponse from "@/dataSource/api/logger-micro-service/services/models/response/GetAllProjectLogGroupsResponse";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options<ProjectsList>({
  name: "project-list",
})
export default class ProjectsList extends Vue {
  @Prop({
    default: () => [] as Array<GetAllProjectLogGroupsResponse>,
    type: Array,
  })
  projects: Array<GetAllProjectLogGroupsResponse>;
  @Prop({ default: true, type: Boolean })
  isLoading: boolean;
    

  headerItemList = [
    {
      title: "Id",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Название сервера",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Название проекта",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
  ];
}
