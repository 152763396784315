import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_list = _resolveComponent("project-list")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Проекты",
    selectSource: false
  }, {
    default: _withCtx((slotProps) => [
      _createVNode(_component_project_list, {
        projects: _ctx.sourceLogsList,
        isLoading: _ctx.isLoading
      }, null, 8, ["projects", "isLoading"])
    ]),
    _: 1
  }))
}