
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import ProjectList from "./project-list.vue";
import GetAllProjectLogGroupsResponse from "@/dataSource/api/logger-micro-service/services/models/response/GetAllProjectLogGroupsResponse";

@Options<ProjectsPage>({
  components: {
    Page,
    ProjectList,
  },
})
export default class ProjectsPage extends Vue {
  sourceLogsList: Array<GetAllProjectLogGroupsResponse> = [];

  isLoading: boolean = true;

  created() {
    this.getSourceLogsList();
  }

  async getSourceLogsList() {
    this.isLoading = true;
    let res = [];
    try {
      res = await this.$api.ProjectLogGroupService.getProjectLogGroupsAsync();
      this.sourceLogsList = res;
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }
}
